<template>
  <button><slot /></button>
</template>
<style lang="scss" scoped>
button {
  @apply py-2 rounded-lg justify-center items-center inline-flex w-full;
  max-width: 260px;
}
</style>
<style lang="scss">
button[disabled] {
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
